import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import AOS from 'aos';
import { CountUp } from 'countup.js';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  //scroll top
  // $('.back-to-top').click(function(event){
  //   $("html,body").animate({scrollTop: 0}, 1000);
  // });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 50) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }

  // tab
  // $('.tab[data-tab]').on('click', function () {
  //   var tabPanes = $(this).data('tabs');
  //   var ID = $(this).data('tab');
  //   $(this).addClass('is-active').siblings().removeClass('is-active');
  //   $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  // });

  var indexBanner = new Swiper('#indexBanner', {
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 20,
    slidesPerView: 1,
    loop: true,
    centeredSlides: true,
    speed: 1000,
    breakpoints: {
      1024: {
        slidesPerView: "auto",
        spaceBetween: 20,
      },
    },
  });

  var currentPage = window.location.pathname;
  if (currentPage === '/' || currentPage === '/en') {
    var storeNum = $('#indexStore').data('taiwan-store');
    var indexStore = new CountUp('indexStore', storeNum, {
        enableScrollSpy: true,
        startVal: 0,
      }
    );
    indexStore.start();
  }

  // page menu mobile
  $('#pageMenuBtn').on('click', function() {
    $(this).toggleClass('active');
    $(this).siblings('#pageMenuDropdown').slideToggle(200);
  });

  // year search
  $('#yearSearchBtn').on('click', function() {
    $(this).toggleClass('active');
    $(this).siblings('#yearSearchDropdown').slideToggle(200);
  });
  $('.year-item').on('click', function() {
    $(this).parent('#yearSearchDropdown').slideToggle(200);
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.drop-option').length) {
      if($('#pageMenuDropdown').is(":visible")) {
        $("#pageMenuDropdown").slideUp(200);
      }
    }
    if(!$(event.target).closest('.post-year-search').length) {
      if($('#yearSearchDropdown').is(":visible")) {
        $("#yearSearchDropdown").slideUp(200);
      }
    }
  });
});
